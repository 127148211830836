<section>
  <div class="container">
    <div class="row"></div>
    <div class="col-md-12">
      <h1 class="text-center">Video Gallery 2016<br /><br /></h1>
      <div class="videolibraryblock">
        <div class="row headingblockred">
          <div class="col-md-8 py-2"><strong>TOPIC</strong></div>
          <div class="col-md-4 py-2"><strong>SPEAKER </strong></div>
        </div>
        <br />
        <!-- <p> -->
        <!--DATA-->
        <!-- Introduction video-->
        <!-- </p> -->
        <div class="row">
          <div class="col-md-8 py-2">
            <a
              (click)="openDialog('sRgsoo5ey8c', 'Youtube')"
              target="_blank"
              rel="noopener"
              >Insight into Corneal Ectasia: Keratoconous, Keratoglobus and
              PMS</a
            >
          </div>
          <div class="col-md-4 py-2">Prof Mohamed Shafik</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('BBg_ls3Nv7w', 'Youtube')"
              >Contact lens: Clinical overview and new advances</a
            >
          </div>
          <div class="col-md-4 py-2">Mr. Mohammed Al Blowi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('NyfB2-uvJhw', 'Youtube')"
              >CXL: Is it really confusing or is it me?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Kashif Baig</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('k4PG9TNT4wI', 'Youtube')"
              >Toric Phakic IOL for Keratoconous</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Saleh Al Messabi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('8ME92EgXXhQ', 'Youtube')"
              >Surface Ablation in Ectatic Cornea, Is it safe? Is it
              Effective</a
            >
          </div>
          <div class="col-md-4 py-2">Prof Mohamed Shafik</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('gP-5ACSsddc', 'Youtube')"
              >Epithelial basement membrane dystrophy: What are the problems?
              How to deal with them?</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('3elZ6aMjGEM', 'Youtube')"
              >Penetrating Keratoplasty: Current roles and clinical pearls</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mohamed Hosny</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('WNyYvICRyqE', 'Youtube')"
              >DALK an overview of methods</a
            >
          </div>
          <div class="col-md-4 py-2">Prof Sheraz Daya</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('cHSQBSU43W8', 'Youtube')"
              >The Basics at Glance: Tear Film, Conjunctiva and Limbal Stem
              cells</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Eg0KEOMFmcw', 'Youtube')"
              >Anti-inflammatory agents in ocular surfaces diseases</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Donald Stone</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('IucBEHLTeAQ', 'Youtube')"
              >AMT and ophthalmic Glues</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Sabah Jastaneiah</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('0EmRnDSduEk', 'Youtube')"
              >Limbal stem Cell Deficiency: Etiology and clinical
              manifestations</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('j-5Yovr60iA', 'Youtube')"
              >Limbal stem Cell Deficiency: Surgical Aspect</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Sheraz Daya</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('QxSxY2sIt98', 'Youtube')"
              >Meibomian Gland Dysfunction and Blepharitis: What is new?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Osama Giledi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('5_IHeNCiDqk', 'Youtube')"
              >Management of dry eye</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mohammed Al Amri</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('ff87M2UCgQY', 'Youtube')"
              >Pigmented and Non-Pigmented Conjunctival lesions</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Dina Abdulmannan</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Otmtq8-jrJk', 'Youtube')"
              >Ocular Surface Squamous Neoplasia (OSSN): Diagnostic pearls</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('I7W9Edo2Wiw', 'Youtube')"
              >Pterygium Management: Conventional and New Surgical Techniques</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Ahmed Al Saadi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('FBJYMbbIISM', 'Youtube')"
              >New innovations in detecting corneal Infections</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mohammed Al Kaabi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('IFK_E_tDl5I', 'Youtube')"
              >Antibiotics Update – Rethinking What we think we know?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Donald Stone</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('HNyNU_TvGKQ', 'Youtube')"
              >Bacterial keratitis: Clinical Pearls and updates</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. osama Giledi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('kqeDQtkLIKs', 'Youtube')"
              >Acanthamoeba Keratitis: Current perspective</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Huda Al Dhaheri</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('XKTyI6kd5f0', 'Youtube')"
              >Peripheral ulcerative keratitis: What is it? What to do?</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('jRaP8bdNGT4', 'Youtube')"
              >Herpes Simplex Keratitis: Clinical Spectrum and Management</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('QT3Dz57VL_8', 'Youtube')"
              >Adenoviral Related Sub-epithelial infiltrates: Is it always
              simple to treat</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Amna Al Mazimi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('7TAVwDXr2sw', 'Youtube')"
              >Sterile corneal ulceration and Neurotrophic keratitis</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('fPflupZA9ZU', 'Youtube')"
              >Corneal Ulceration and perforation: Medical and Surgical
              management</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Huda Al Dhaheri</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('vtgSnnpmABo', 'Youtube')"
              >Femtosecond laser keratoplasty</a
            >
          </div>
          <div class="col-md-4 py-2">Prof Sheraz Daya</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('bn66K-CIFVE', 'Youtube')"
              >Artifical Cornea Options in 2017</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Kashif Baig</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('7Xle2dSDrYg', 'Youtube')"
              >Keratoprosthesis: types, patient selection and complications</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mark Wilkins</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Et5XQ7En83Q', 'Youtube')"
              >ABC of Anterior Segment Trauma: Clinical and Surgical
              Perspective</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Donald Stone</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Et5XQ7En83Q', 'Youtube')"
              >Update on artificial Iris</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Kashif Baig</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('pW6barVFHPQ', 'Youtube')"
              >Chemical burns: acute stage management 10 minutes</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Harminder Dua</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('jwvWKjL1xQo', 'Youtube')"
              >Traumatic Cataract: Approach and IOL Options for Eyes with
              comprised capsules</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Kashif Baig</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('G11L1LAvq_8', 'Youtube')"
              >Preoperative Workup in refractive surgery</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Saeed Al Ghamdi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('FO88q0ctkLw', 'Youtube')"
              >Topography: How to screen for ectasia</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Mazen Sinjab</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('SmWQtLIJMgk', 'Youtube')"
              >Selecting the right refractive procedure for your patient:
              Clinical Pearls</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Tamer Gamaly</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('sfLE_zqylT0', 'Youtube')"
              >Flap in Refractive Surgery: concepts, uses and complication</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mohamed Hosny</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('UJC9KKe1AC0', 'Youtube')"
              >Surface Corneal Ablation: concepts, uses and complication</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Sharif Emereh</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('oXzMdQczTwQ', 'Youtube')"
              >Wavefront Aberrometry Basic</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Kashif Baig</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('KvN2ivErODY', 'Youtube')"
              >Refractive tenticule extraction; is it replacing the ablation
              lasers?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Emad Alilo</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('sVKNzD1v7cw', 'Youtube')"
              >T-CAT (Topography-guided Customized Ablation Treatment) for
              irregular cornea</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Saleh Al Messabi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('cpx9Y2FQaWM', 'Youtube')"
              >Combined approach for tackling post-keratoplasty astigmatism</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mohamed Hosny</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('o4AprzN2B7w', 'Youtube')"
              >Presbyopia Updates</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Osama Giledi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('64ByluhFFJU', 'Youtube')"
              >Decision making in phakic IOL: who, why and where?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Hisham Hamdi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('STidBeACfOQ', 'Youtube')"
              >Retreatment decision and options</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Sabah Jastaneiah</div>
        </div>
      </div>
      <div class="sep25px">&nbsp;</div>
      <div class="sep25px">&nbsp;</div>
    </div>
  </div>
</section>
