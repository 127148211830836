<!-- Page Banner end -->
<!-- ts intro start -->
<section class="ts-contact" id="contact">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <h2 class="section-title text-center">
          <span>Get Information</span>
          Organized by
        </h2>
        <!-- <div class="organizing">
          <img src="assets/event/blue_ocean.png" width="210px" alt="" />
        </div> -->
      </div>
      <!-- col end-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="single-intro-text single-contact-feature">
          <div class="organizing">
            <img src="assets/event/blue_ocean.png" style="width: 230px" />
          </div>
          <!-- < <p><strong>Landline:</strong> +971 44 502 485</p> -->
          <p><strong>Cellular: </strong> +971 4 450 2485</p>
          <p>
            <strong>Email: </strong>
            <a href="mailto:info@blueocean-me.com" target="_blank"
              >info@blueocean-me.com</a
            >
          </p>
          <p>
            <strong>Website: </strong>
            <a href="https://blueocean-me.com" target="_blank"
              >https://blueocean-me.com</a
            >
          </p>
          <p>
            <strong>address: </strong> 26 b, 17th Floor, Latifa Tower, Sheikh
            Zayed Road, Dubai, U.A.E
          </p>
          <span class="count-number fa fa-paper-plane"></span>
        </div>
        <!-- single intro text end-->
        <div class="border-shap left"></div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
</section>
<!-- ts contact end-->
