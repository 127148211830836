<!-- <h2 mat-dialog-title>Install Angular</h2> -->
<div mat-dialog-content class="mat-typography">
  <iframe
    *ngIf="type=='Vimeo'"
    [src]="'https://player.vimeo.com/video/'+name  | safeUrl"
    width="560"
    height="315"
    frameborder="0"
    title="My video"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  ></iframe>

  <iframe
    *ngIf="type=='Youtube'"
    width="560"
    height="315"
    [src]="'https://www.youtube.com/embed/'+name | safeUrl"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <img *ngIf="type=='image'" src="{{name}}" class="img-fluid" />
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</div>
