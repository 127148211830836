<section>
  <div class="container-fluid">
    <div class="row">
      <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->

      <div class="col text-center">
        <a (click)="backClicked()" class="btn-back btn">Back to booth</a>
        <br />
        <br />
        <img src="assets/event/exh/{{ image }}" class="img-fluid" alt="" />
      </div>
    </div>

    <!-- Container end -->
  </div>
</section>
