<section>
  <div class="container">
    <div class="row"></div>
    <div class="col-md-12">
      <h1 class="text-center">Video Gallery 2018<br /><br /></h1>
      <div class="videolibraryblock">
        <div class="row headingblockred">
          <div class="col-md-8"><strong>TOPIC</strong></div>
          <div class="col-md-4"><strong>SPEAKER </strong></div>
        </div>
        <br />
        <!-- <p> -->
        <!--DATA-->
        <!-- Introduction video-->
        <!-- </p> -->
        <div class="row">
          <div class="col-md-8 py-2">
            <a (click)="openDialog('jUruan-PLh4', 'Youtube')" rel="noopener"
              >Anterior vitrectomy for the anterior segment surgeon</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Felipe Dhawahir</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('tFX-hAcVcrs', 'Youtube')"
              >Parsplana vitrectomy: Indications and techniques</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Hany Hamza</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('rUPTDOzghu8', 'Youtube')"
              >Small guage vitrectomy. Tips and Tricks</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Felipe Dhawahir</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('41lS5pwTd10', 'Youtube')"
              >Vitrectomy for complicated cataract surgery</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Usman Mahmoud</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('lG8ypMBs2Uo', 'Youtube')"
              >Proliferative vitreoretinopathy. Update on pathophysiology and
              management</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Hassan Mortada</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('z8R2ANy2B6o', 'Youtube')"
              >Vitreomacular interface diseases: presentation and management</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Emad Abboud</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('tG5Y4sAkSFw', 'Youtube')"
              >Surgical approach to complicated Proliferative Diabetic
              Retinopathy</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Hassan Mortada</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('2hhDjsCFBIo', 'Youtube')"
              >Recurrent vitreous haemorrhage alter diabetic vitrectomy</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Felipe Dhawahir</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('jg7W4JhukeA', 'Youtube')"
              >Ep idemiology and screening: magnitude of the problem</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Ahmad Hassan</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Pulyjbm7Tzk', 'Youtube')"
              >Imaging: From widefield FFA to OCTA. Did it really affect my
              clinical practice?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Avi Gurbaxani</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('MAGJ7W0B1fE', 'Youtube')"
              >Review of pathophysiology and classification of Diabetic
              Retinopathy</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Rashid Al Suwadi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('ZuOPnjPKrFY', 'Youtube')"
              >Diabetic macular oedema: Treatment algorithms and
              cost-effectiveness</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mohamed Naser</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('o40_pNxq9tY', 'Youtube')"
              >Diabetic macular oedema: Treatment failure/resistance</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Ibraheem El Ghrably</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('lVQoAmwJvZ0', 'Youtube')"
              >Comparison of short-pulse and micropulse laser in diabetic
              macular oedema</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Igor Kozak</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('X1qlXp0Y-44', 'Youtube')"
              >Laser for diabetic macular oedema. Is it still a valid option in
              this anti-VEGF era?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Ahmad Kashab</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('q8KLNoNBrls', 'Youtube')"
              >Evidence-based practice for RVO macul.ar oedema</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Madhav Rao</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Zf3gjulFtk8', 'Youtube')"
              >CRVO; what should we know?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Ahmed Al Barky</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('tKtWDKsSPME', 'Youtube')"
              >Retinal arterial occlusive diseases. Update on management.</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Haitham Al Bakri</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('jsiofsad3Qw', 'Youtube')"
              >Cystoid macular oedema: causes and management</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Mohamed Al Abri</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('DPEtokTzh5E', 'Youtube')"
              >Laser PRP vs. anti-VEGF for proliferative diabetic retinopathy</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Raef Emile</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('0MWlu_YLCRc', 'Youtube')"
              >The Effects of Multispot PRP on Retinal Sensitivity and
              Driving</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Michel Michaelides</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('k_0XYqd08ZE', 'Youtube')"
              >Assessment of the eye with uveitis and principles of
              management</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Susan Lightman</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('DadlyoO3zzM', 'Youtube')"
              >Assessment of the eye with uveitis and principles of
              management</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Susan Lightman</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('4xUx7zujh2k', 'Youtube')"
              >Services for the visually impaired in the UAE</a
            >
          </div>
          <div class="col-md-4 py-2">Ms. Maysa Obied</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('tn7GVpRaIjE', 'Youtube')"
              >Macular Oedema in Retinits Pigmentosa – Pathogenesis and
              Management</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Michel Michaelidas</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('6lBUNnmJnC0', 'Youtube')"
              >Vitrectomy or expulsive Hemorrhage After Trabeculectomy</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Hany Hamza</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('iwa8TD8wXa8', 'Youtube')"
              >How did i get out of jail?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. CK Patel</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Nbe-73xCJ_g', 'Youtube')"
              >Assessment of the eye with uveitis and principles of
              management</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Susan Lightman</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('_j_lW1NaoqA', 'Youtube')"
              >Imaging, Screening and telemedicine for ROP: Who, how and for how
              long?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. CK Patel</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('YzRjFxOCTfo', 'Youtube')"
              >Challenging Clinical Cases</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Michel Michaelidas</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('QG3u6pY0Y-I', 'Youtube')"
              >Management of macular fold</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Ehab El Rayes</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('tpCoBq4dyo8', 'Youtube')"
              >Light at the end of the tunnel</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Felipe Dhawahir Scala</div>
          <div class="col-md-8 py-2">
            <a>Update on Scleritis</a>
          </div>
          <div class="col-md-4 py-2">Dr. Tahra Al Mahmoud</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('fYBo9k0Vcjk', 'Youtube')"
              >Complicated Giant Retinal Tear</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Faisal Fayyad</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('iMkEeLh6L2Y', 'Youtube')"
              >latest theories on patheogenesis of ROP</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Abeer Al Ali</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Xa-mXm24-MY', 'Youtube')"
              >Successful mobilization of embolus in a major branch artery with
              surgery</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Khalid Al Sabti</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('TJ_Qa802JEo', 'Youtube')"
              >Subfoceal PFCL Bubbles in myopic eye with posterior staphyloma</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Hassan Mortada</div>
        </div>
      </div>
      <div class="sep25px">&nbsp;</div>
      <div class="sep25px">&nbsp;</div>
    </div>
  </div>
</section>
