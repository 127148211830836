<section>
  <div class="container">
    <div class="row"></div>
    <div class="col-md-12">
      <h1 class="text-center">
        <img src="assets/img2/adore-logo-2020.png" style="width: 350px" />
        <br /><br />Video Gallery 2020<br /><br />
      </h1>
      <div class="videolibraryblock">
        <div class="row headingblockred">
          <div class="col-md-8 py-2"><strong>TOPIC</strong></div>
          <div class="col-md-4 py-2"><strong>SPEAKER </strong></div>
        </div>
        <br />
        <!-- <p> -->
        <!--DATA-->
        <!-- Introduction video-->
        <!-- </p> -->
        <div class="row">
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Q-T-4P8TxxM', 'Youtube')" rel="noopener"
              >4th Adore 2020 Pediatrics &amp; Oculoplastics</a
            >
          </div>
          <div class="col-md-4 py-2">Introduction</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('nUGRlaK9cys', 'Youtube')" rel="noopener"
              >Opening</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Abdelziz Al Rajhi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('1C6JLBuV09Y', 'Youtube')" rel="noopener"
              >Opening</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Ahmed Al Saadi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('MnkvNauBtMg', 'Youtube')" rel="noopener"
              >Incisions, Sutures, Needles, Wound Healing</a
            >
          </div>
          <div class="col-md-4 py-2">Professor Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('jUBYAjjqkDg', 'Youtube')" rel="noopener"
              >Conquering Orbital Inflammation and Pitfalls in eyelid and
              orbital infection</a
            >
          </div>
          <div class="col-md-4 py-2">Professor Richard C. Allen</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('t4E9tdKoBz0', 'Youtube')" rel="noopener"
              >The Sacred and Hidden is Revealed</a
            >
          </div>
          <div class="col-md-4 py-2">Mohammad Javed Ali MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('1AS5pVRVbsw', 'Youtube')" rel="noopener"
              >Radiology in Ophthalmology</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Manzoor Ahmed</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('I5azowJGDtI', 'Youtube')" rel="noopener"
              >One technique for all lower lid entropion</a
            >
          </div>
          <div class="col-md-4 py-2">Professor Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('h5kjfO40sSk', 'Youtube')" rel="noopener"
              >Immunohistochemical Expression of Programmed Cell Death – L1
              (PD-L1) protein and Mismatch repair mutation in Orbital Tumors</a
            >
          </div>
          <div class="col-md-4 py-2">Mohammad AlSemari, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('mU6e7PYF9ok', 'Youtube')" rel="noopener"
              >Is it a Subperiosteal Abscess or Hematoma?</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Hend AlSafran</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('u59MKBa_qBQ', 'Youtube')" rel="noopener"
              >Bilateral orbital granulocytic sarcoma (Chloroma)</a
            >
          </div>
          <div class="col-md-4 py-2">Daniya Alturkistani</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('doGGKUXn7Lg', 'Youtube')" rel="noopener"
              >Conjunctival Swelling</a
            >
          </div>
          <div class="col-md-4 py-2">Khalid Alshormar, MBBS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('C4R5UgFbVJM', 'Youtube')" rel="noopener"
              >Adult Orbital Xanthogranulomatous Disease (AOXGD): A Case
              Report</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Saleh AlRashed</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('lXRbaPy_SvU', 'Youtube')" rel="noopener"
              >How to Examine the Uncooperative Child</a
            >
          </div>
          <div class="col-md-4 py-2">Kristen Alkhamis, Senior Orthoptist</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('gtDnHmKdVj4', 'Youtube')" rel="noopener"
              >Orthoptic Assessment of Superior Oblique Palsy, Unilateral VS
              Bilateral</a
            >
          </div>
          <div class="col-md-4 py-2">Lina AlKahmous Msc. OC(C)</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('AjEvudaZdk0', 'Youtube')" rel="noopener"
              >Significance of retractive errors in children – prescribe</a
            >
          </div>
          <div class="col-md-4 py-2">Shahida Samsuddin</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('NCULQSgmN_o', 'Youtube')" rel="noopener"
              >Amblyopia what we need to know</a
            >
          </div>
          <div class="col-md-4 py-2">Mr. Vinod Sharma</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('w1TTmzecM7s', 'Youtube')" rel="noopener"
              >Electrophysiology and how can it help us?</a
            >
          </div>
          <div class="col-md-4 py-2">Professor Arif Khan MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('EO9GSaNZEwM', 'Youtube')" rel="noopener"
              >Do all roads lead to orbit?</a
            >
          </div>
          <div class="col-md-4 py-2">Professor Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('pCSkVnFRjaM', 'Youtube')" rel="noopener"
              >Orbital Mass: Take it or Leave it</a
            >
          </div>
          <div class="col-md-4 py-2">Adel Alshuhaibani, MD, FRCS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('MuDXBp7njXQ', 'Youtube')" rel="noopener"
              >Applied Anatomy of the eyelids &amp; Orbit</a
            >
          </div>
          <div class="col-md-4 py-2">Professor Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('3YpVGn0zenw', 'Youtube')" rel="noopener"
              >Vascular Lesions of the Orbit, Nomenclature and Trends in
              Treatment</a
            >
          </div>
          <div class="col-md-4 py-2">Diego Srianese &amp; Hamad Alsulaiman</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('sV-Ain7imV4', 'Youtube')" rel="noopener"
              >Cutting edge management in thyroid eye disease</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD PhD FaCS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('iKueQJrBvtk', 'Youtube')" rel="noopener"
              >Thyroid Eye Disease – Less known &amp; often missed signs</a
            >
          </div>
          <div class="col-md-4 py-2">Professor Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('opTkUoS8p1A', 'Youtube')" rel="noopener"
              >Molecularly targeted therapy in oculoplatic surgery</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD PhD FaCS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('e1n_EBcDdQg', 'Youtube')" rel="noopener"
              >Eyes led to It</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Asma Hamed Al Flaiti</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('fDMnenp9fGc', 'Youtube')" rel="noopener"
              >Pediatric Retinitis</a
            >
          </div>
          <div class="col-md-4 py-2">Ashwaq Bin Amro</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('cHiyPz1tN6w', 'Youtube')" rel="noopener"
              >Disc Swelling Recurrence</a
            >
          </div>
          <div class="col-md-4 py-2">Rawdha Al Nuaimi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('kjzntAV9kJ0', 'Youtube')" rel="noopener"
              >Oscular genetic conditions not to be missed</a
            >
          </div>
          <div class="col-md-4 py-2">Arif Khan MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Lc8V3agjJPE', 'Youtube')" rel="noopener"
              >Red Flags in Pediatric Opthalmology</a
            >
          </div>
          <div class="col-md-4 py-2">Amani Albakri, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('xZOd-9olFdU', 'Youtube')" rel="noopener"
              >Paediatric Penetrating Keratoplasty</a
            >
          </div>
          <div class="col-md-4 py-2">Michael O’Keeffe</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('1biGgMFqu9A', 'Youtube')" rel="noopener"
              >My approach to anterior segment dysgenesis</a
            >
          </div>
          <div class="col-md-4 py-2">Arif Khan MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Au59GdERWN0', 'Youtube')" rel="noopener"
              >Is it just a lazy cornea?</a
            >
          </div>
          <div class="col-md-4 py-2">Jane Ashworth</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('AeLZL6COLGI', 'Youtube')" rel="noopener"
              >Does This Pupil Look Normal?</a
            >
          </div>
          <div class="col-md-4 py-2">Lua Eldwick, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('o3uHDH16rug', 'Youtube')" rel="noopener"
              >Neurofibromatosis How often d we see them?</a
            >
          </div>
          <div class="col-md-4 py-2">Mr. Vinod Sharma</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('q6aCCsVz40Y', 'Youtube')" rel="noopener"
              >Neurofibromatosis and Angle Closure Glaucoma In a Child</a
            >
          </div>
          <div class="col-md-4 py-2">Reham AlQahtani, MBBS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('OWwHlbPxD3k', 'Youtube')" rel="noopener"
              >How Revising Diagnosis Saved The Eye</a
            >
          </div>
          <div class="col-md-4 py-2">Mais Aljbour MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('2woiVXCuiK4', 'Youtube')" rel="noopener"
              >Refractive Surgery in Pediatric Age Group</a
            >
          </div>
          <div class="col-md-4 py-2">Micheal O’Keeffe</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('wKSb5eho9J8', 'Youtube')" rel="noopener"
              >A Simplified Approach to Eyelid Reconstruction</a
            >
          </div>
          <div class="col-md-4 py-2">Omar Hadid</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('GVkEKNlB5QE', 'Youtube')" rel="noopener"
              >Crossroads between external and conjunctival ptosis surgery</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('zpOPkv-M5G0', 'Youtube')" rel="noopener"
              >When Ptosis goes wrong</a
            >
          </div>
          <div class="col-md-4 py-2">Essam EiToukhy, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Ob-ZDgI7cxs', 'Youtube')" rel="noopener"
              >Congenital ptosis surgery: when and what?</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('4QkG67EV520', 'Youtube')" rel="noopener"
              >Congenital ptosis surgery: when and what?</a
            >
          </div>
          <div class="col-md-4 py-2">Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('ajJE9AaatJg', 'Youtube')" rel="noopener"
              >One technique for all lower lid ectropion</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('BpS8clwhzhU', 'Youtube')" rel="noopener"
              >One technique for all lower lid ectropion</a
            >
          </div>
          <div class="col-md-4 py-2">Mohammad Javed Ali, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('40AQQxrZWm8', 'Youtube')" rel="noopener"
              >Hornor’s Syndrome with Trigeminal Autonomic headache caused by
              Multinodular goiter</a
            >
          </div>
          <div class="col-md-4 py-2">Abdulmalik Alyahya, MBBS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('zOdbfXGC0Ds', 'Youtube')" rel="noopener"
              >5-FU &amp; Lower Lid Retraction</a
            >
          </div>
          <div class="col-md-4 py-2">Ashwaq Bin Amro</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('mXbUUVw4YNA', 'Youtube')" rel="noopener"
              >Bilateral upper eyelid swelling</a
            >
          </div>
          <div class="col-md-4 py-2">Habibullah Eatamadi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('LG_TlT8KhLY', 'Youtube')" rel="noopener"
              >Examinations and investigations in oculoplastics: Labs, Imaging
              and fine needle aspirations</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('TeahaGZaW1c', 'Youtube')" rel="noopener"
              >Clinical Evaluation of the Lacrimal System</a
            >
          </div>
          <div class="col-md-4 py-2">Muhammad Javed Ali, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('JHYuLKN9GSA', 'Youtube')" rel="noopener"
              >Lacrimal drainage systems tumors</a
            >
          </div>
          <div class="col-md-4 py-2">Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Lf-wLSziczA', 'Youtube')" rel="noopener"
              >Anaphthalmic socket</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('LrpG4TnnpJo', 'Youtube')" rel="noopener"
              >Ophthalmic Surgeries in Facial Nerve Palsy so Much to Offer</a
            >
          </div>
          <div class="col-md-4 py-2">Adel Alsuhaibani, MD, FRCS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('s-6NDUb3S_o', 'Youtube')" rel="noopener"
              >An approach to paediatic uveitis</a
            >
          </div>
          <div class="col-md-4 py-2">Jane Ashworth</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('sMJxLbzAKWo', 'Youtube')" rel="noopener"
              >Current Trend in Management of ROP</a
            >
          </div>
          <div class="col-md-4 py-2">Micheal O’Keeffe</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('9okLkGGbHxM', 'Youtube')" rel="noopener"
              >Surgical treatment of Retinopathy of Prematurity</a
            >
          </div>
          <div class="col-md-4 py-2">Antonella D Aponte, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('QrMu5EHeT1w', 'Youtube')" rel="noopener"
              >Uveitic Glaucoma – Treatment Options</a
            >
          </div>
          <div class="col-md-4 py-2">Abeer Khalid Al Ali</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('bTu7Nsq-i80', 'Youtube')" rel="noopener"
              >Uveitic cataract – my preferred approach</a
            >
          </div>
          <div class="col-md-4 py-2">Jane Ashworth</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('jfHkgTY745I', 'Youtube')" rel="noopener"
              >Retinoblastoma</a
            >
          </div>
          <div class="col-md-4 py-2">Abeer Khalid Al Alik</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('8jwdhl9rACw', 'Youtube')" rel="noopener"
              >Cosmetic Botulinum Toxin Treatment</a
            >
          </div>
          <div class="col-md-4 py-2">Hamad M Alsulaiman MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Al549tYMDYM', 'Youtube')" rel="noopener"
              >Producing Oculoplastic Surgery Videos</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('l0bQ_-kggpQ', 'Youtube')" rel="noopener"
              >Evolution of Pediatric Cataract</a
            >
          </div>
          <div class="col-md-4 py-2">Michael O’Keeffe</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('BkSciwPjvDk', 'Youtube')" rel="noopener"
              >PFV – operate or not?</a
            >
          </div>
          <div class="col-md-4 py-2">Arif Khan MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('6Ft1Lu6x7Bk', 'Youtube')" rel="noopener"
              >IOL Children Under 2 Years of Age</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Syed Muhammad Asad Ali</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('VSUGOXCQI1U', 'Youtube')" rel="noopener"
              >Deep sclerectomy</a
            >
          </div>
          <div class="col-md-4 py-2">Arif Khan MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('6V15EmyzCXU', 'Youtube')" rel="noopener"
              >Management of Posterior Capsular Opacification</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Hayat Ahmad Khan</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('XjUinh4n3JY', 'Youtube')" rel="noopener"
              >A Diagnostic Challenge: Paroxymal Diplopia and Strabismus</a
            >
          </div>
          <div class="col-md-4 py-2">Sara M. Alhilali, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('wrYktSy0Oi8', 'Youtube')" rel="noopener"
              >Child with papilledema</a
            >
          </div>
          <div class="col-md-4 py-2">Manal Alzaabi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('8sSDkCInkh4', 'Youtube')" rel="noopener"
              >Mystery of the NLD Obstruction</a
            >
          </div>
          <div class="col-md-4 py-2">Fatima Habroosh</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('FYd-kDbNfv8', 'Youtube')" rel="noopener"
              >ROP Shall we treat or wait?</a
            >
          </div>
          <div class="col-md-4 py-2">Mariam Al Neyadi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('u4hXtkzvqqc', 'Youtube')" rel="noopener"
              >Pediatric Case Presentation</a
            >
          </div>
          <div class="col-md-4 py-2">Imam al Hammadi</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Xzi37JOOpRk', 'Youtube')" rel="noopener"
              >Management of a case of complex Nystagmus</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Tulika Kar MS, FRCS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('U4JyqDt_xTQ', 'Youtube')" rel="noopener"
              >Esotropia When to Intervene</a
            >
          </div>
          <div class="col-md-4 py-2">Michael O’Keeffe</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('46J0CaCOsDw', 'Youtube')" rel="noopener"
              >Extropia – Can you make them orthophoric?</a
            >
          </div>
          <div class="col-md-4 py-2">Mr. Vinod Sharma</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('T3Oy4gIivbg', 'Youtube')" rel="noopener"
              >A ands V pattern Do we really need to correct them</a
            >
          </div>
          <div class="col-md-4 py-2">Dr. Hayat Ahmad Khan</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('elUMxzqGN8I', 'Youtube')" rel="noopener"
              >Squint Surgeries: How to Avoid A Re – Do?</a
            >
          </div>
          <div class="col-md-4 py-2">Laila Taha Ababneh, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Qn60pugLxWo', 'Youtube')" rel="noopener"
              >The Dos And Don’ts When Assessing A child with Nystagmus</a
            >
          </div>
          <div class="col-md-4 py-2">Lua Eldweik, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('rAGDsw3RejM', 'Youtube')" rel="noopener"
              >Strabismus from Hell</a
            >
          </div>
          <div class="col-md-4 py-2">Irfan Khan FRCOphth</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('2vZTFy3M5lk', 'Youtube')" rel="noopener"
              >Nystagmus Surgery Dose it Really Work?</a
            >
          </div>
          <div class="col-md-4 py-2">Faten Almajed, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('z0h1jl6L8eo', 'Youtube')" rel="noopener"
              >Multiple Squint Surgeries: is There Anything Left to be done</a
            >
          </div>
          <div class="col-md-4 py-2">Laila Taha Ababneh, MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('7ro8MoFilCw', 'Youtube')" rel="noopener"
              >When do I use Botox</a
            >
          </div>
          <div class="col-md-4 py-2">Abeer Al Ali – SKMC</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('9tHY_FNDwxs', 'Youtube')" rel="noopener"
              >A Rare Complication of Strabismus Surgery</a
            >
          </div>
          <div class="col-md-4 py-2">Irfan Khan FRCOphth</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('Ug2f8Ibau68', 'Youtube')" rel="noopener"
              >Botulimum Toxin: the Judicious Use</a
            >
          </div>
          <div class="col-md-4 py-2">Omar Hadid</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('5-8duOqNxhQ', 'Youtube')" rel="noopener"
              >Tussue Fillers &amp; Fat drafts in Orbitofacial disorder</a
            >
          </div>
          <div class="col-md-4 py-2">Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('ItIeohyqbJM', 'Youtube')" rel="noopener"
              >Upper blepharoplasty: tricks to get the best results</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD PhD FaCS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('gCKRETG2uvw', 'Youtube')" rel="noopener"
              >Lower Blepharoplasty &amp; Midface lift</a
            >
          </div>
          <div class="col-md-4 py-2">Gangadhar Sundar</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('KI6UxAdaMAw', 'Youtube')" rel="noopener"
              >Brow lift: when to do what</a
            >
          </div>
          <div class="col-md-4 py-2">Richard C. Allen MD PhD FaCS</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('P5rJiwEhszo', 'Youtube')" rel="noopener"
              >Lower eyelid blepharoplasty complications</a
            >
          </div>
          <div class="col-md-4 py-2">Prof. Hachemi Nezzar MD</div>
          <div class="col-md-8 py-2">
            <a (click)="openDialog('2hh8L5Q5QxA', 'Youtube')" rel="noopener"
              >Upper Lid Blepharoplasty Complications</a
            >
          </div>

          <div class="col-md-4 py-2">Habibullah Eatamadi</div>
        </div>
      </div>
      <div class="sep25px">&nbsp;</div>
      <div class="sep25px">&nbsp;</div>
    </div>
  </div>
</section>
