<section class="ts-blog section-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title">
          <span>Events</span>
          Live / Upcoming

          <button (click)="doGet()">Besm allah</button>
        </h2>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-lg-4" *ngFor="let con of conferences">
        <div class="post">
          <div class="post-media post-image">
            <a routerLink="event/{{ con.slug }}" [queryParams]="{ id: con.id }"
              ><img
                src="assets/images/blog/blog1.jpg"
                class="img-fluid"
                alt=""
              />
              <!-- https://blueocean-me.com/cm_ups/banners/{{ con.logo }}} -->
            </a>
          </div>

          <div class="post-body">
            <div class="post-meta">
              <span class="post-author">
                <a href="#">BY Admin</a>
              </span>

              <div class="post-meta-date">
                {{ con.start_date | date: "longDate" }}
              </div>
            </div>
            <div class="entry-header">
              <h2 class="entry-title">
                <a
                  routerLink="event/{{ con.slug }}"
                  [queryParams]="{ id: con.id }"
                  >{{ con.name }}
                </a>
              </h2>
            </div>
            <!-- header end -->

            <!-- <div class="entry-content">
              <p>
                {{
                  con.description == null
                    ? ""
                    : con.description.substring(0, 100)
                }}
              </p>
            </div> -->

            <div class="post-footer">
              <a
                routerLink="event/{{ con.slug }}"
                [queryParams]="{ id: con.id }"
                class="btn-link"
                >Read More <i class="icon icon-arrow-right"></i
              ></a>
            </div>
          </div>
          <!-- post-body end -->
        </div>
        <!-- post end-->
      </div>
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
  <!-- shap image-->
  <div class="speaker-shap">
    <img class="shap2" src="assets/images/shap/news_memphis2.png" alt="" />
  </div>
</section>
