<footer class="bg-primary-3">
  <!--Container-->
  <div class="container spacer-double-sm">
    <!--Row-->
    <div class="row mb-5">
      <div class="col-md-4 mb-5 mb-md-0">
        <div class="media">
          <img
            class="max-width-sm mr-3"
            src="assets/svg/contact-icon.svg"
            alt=""
          />
          <div class="media-body">
            <h4 class="text-white h6 mb-0">Call us</h4>
            <span class="d-block text-light font-size-15"
              >+971 58 594 74 06
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-5 mb-md-0">
        <div class="media">
          <img
            class="max-width-sm mr-3"
            src="assets/svg/email-icon.svg"
            alt=""
          />
          <div class="media-body">
            <h4 class="text-white h6 mb-0">Email us</h4>
            <span class="d-block text-light font-size-15"
              >support@focus-gulf.com</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-5 mb-md-0">
        <div class="media">
          <img
            class="max-width-sm mr-3"
            src="assets/svg/location-icon.svg"
            alt=""
          />
          <div class="media-body">
            <h4 class="text-white h6 mb-0">Address</h4>
            <span class="d-block text-light font-size-15"
              > Nest Office,TRYP By Wyndham Hotel, Dubai, UAE,Dubai, UAE, P.O BOX 391186 – UAE.
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--End row-->
    <!--Row-->
    <!-- <div class="row justify-content-center">
      <div class="col-auto">
        <ul class="list-inline mb-2 mt-3">
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-facebook-f"></span
            ></a>
          </li>
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-twitter"></span
            ></a>
          </li>
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-instagram"></span
            ></a>
          </li>
          <li class="list-inline-item">
            <a class="social-icon" href="#"
              ><span class="fab fa-twitter"></span
            ></a>
          </li>
        </ul>
      </div>
    </div> -->
    <!--End row-->
    <!--Row-->
    <div class="row justify-content-center">
      <div class="col-auto">
        <p class="font-size-14 text-muted text-center mb-5">
          &copy; 2021 all rights reserved - Powered by
          <a
            class="text-light"
            target="_blank"
            href="http://primesolutionsweb.com"
            >Prime Solutions.</a
          >.
        </p>
      </div>
    </div>
    <!--End row-->
  </div>
  <!--End containers-->
</footer>
