<section id="videoLib" style="padding: 150px 0">
  <!-- background-color: #f5f8fb -->
  <!--Container-->
  <div class="container spacer-double-sm">
    <!--Row-->
    <div class="row">
      <div class="col">
        <div class="mb-5 pb-5 text-center">
          <!-- <span class="icon-title icon-bg-dark rounded-circle mb-3">
            <img
              class="icon-title-inner"
              src="assets/svg/topic-icon.svg"
              alt=""
            />
          </span> -->
          <h2 class="h1">Video Library</h2>
          <!-- <p class="w-md-60 mx-auto mb-0 lead">
            Whether you’re new to the speaking field or a seasoned pro you’ll
            connect with old colleagues and make valuable new friends.
          </p> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mb-3">
          <div class="card">
            <a [routerLink]="['/', 'library2020']">
              <img
                class="card-img-top"
                src="assets/img2/adore-2020.jpg"
                alt=" "
              />
            </a>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="card">
            <a [routerLink]="['/', 'library2019']">
              <img
                class="card-img-top"
                src="assets/img2/adore-2019.jpg"
                alt=" "
              />
            </a>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="card">
            <a [routerLink]="['/', 'library2018']">
              <img
                class="card-img-top"
                src="assets/img2/adore-2018.jpg"
                alt=" "
              />
            </a>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="card">
            <a [routerLink]="['/', 'library2016']">
              <img
                class="card-img-top"
                src="assets/img2/adore-2016.jpg"
                alt=" "
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--End row-->
    <!-- <div class="card-columns-count card-count-4">
        <div class="card rounded mb-3">
          <div class="card-body p-5 justify-content-between">
            <div class="mb-3">
              <img class=" " src="assets/img2/adore-2017.jpg" alt=" " />
            </div>
          </div>
        </div>
        <div class="card rounded mb-3">
          <div class="card-body p-5 justify-content-between">
            <div class="mb-3">
              <img class=" " src="assets/img2/adore-2019.jpg" alt=" " />
            </div>
          </div>
        </div>
        <div class="card rounded mb-3">
          <div class="card-body p-5 justify-content-between">
            <div class="mb-3">
              <img class=" " src="assets/img2/adore-2016.jpg" alt=" " />
            </div>
          </div>
        </div>
        <div class="card rounded mb-3">
          <div class="card-body p-5 justify-content-between">
            <div class="mb-3">
              <img class=" " src="assets/img2/adore-2016.jpg" alt=" " />
            </div>
          </div>
        </div>
      </div> -->
    <!--Row-->
    <!--        <div class="row">
            <div class="col">
                <div class="mt-5 pt-5 text-center"> <a class="btn btn-secondary btn-wide " style="background-color: #7b333a ;border-color:#7b333a" href="#">
              View all Topics
            </a>
              </div>
            </div>
          </div>-->
    <!--End row-->
  </div>
  <br /><br />
  <!--End container-->
</section>
