<!-- ts sponsors start-->
<section id="ts-sponsors" class="ts-sponsors">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-12 mx-auto">
        <h2 class="section-title text-center">
          <!-- <span>Who Healps us</span> -->
          Our Sponsors
        </h2>
      </div>
    </div>
    <!--/ Title row end -->
    <div class="sponsors-wrap">
      <div class="row sponsor-padding text-center">
        <div class="col-lg-12">
          <a href="#" class="sponsors-logo1">
            <img
              class="img-fluid"
              src="assets/event/sponsors/sanofi.svg"
              style="height: 145px;"
              alt=""
            />
          </a>
        </div>
        <!-- Col 1 end -->
      </div>
    </div>

    <!--/ Content row 1 end -->
  </div>
  <!--/ Container end -->
</section>
<!-- ts ts sponsors end-->
