<section id="about" class="o-hidden">
  <!--Container-->
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
      
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-md-9">


       <strong>Dear healthcare professionals,  </strong><br /> <br>
        
       On behalf of the steering committee of ADORE, I am delighted to invite you to our  5th ADORE 2023 Cornea and Refractive, to be held on January 20-21, 2023. The organizing and scientific committee are a faculty of top-level ophthalmologists in Abu Dhabi. 

        <br /><br />
        The event aims to provide a comprehensive review across the full spectrum of subspecialties in ophthalmology focusing on one specialty each year. In 2023, we will be focussing on Cornea and Refractive.   
        <br /><br />
        Abu Dhabi Ophthalmology Review (ADORE) will bring together 650 ophthalmologists from across the Gulf region to exchange and share their experiences. The review will cover the basics, the updated clinical knowledge, and research results. The comprehensive program is focused on pure scientific and educational data and will add value to practicing ophthalmologists interested in improving their professional careers and patient quality care.
        <br /><br />
       
        We look forward to welcoming you onboard on January 20-21, 2023. 
        <br /><br />
        Best regards,
    
      
        <br />
        Dr. Ahmed El-Saadi
       <br/>
        Conference President 

      </div>
      <div class="col-md-3 text-right">
        <br /><br />
        <img src="assets/event/president.jpg" alt="" />
        <br />
        Dr. Ahmed El-Saadi
       <br/>
        Conference President 

      </div>
      <br><br>
     <!--  <div class="col-md-3 text-center">
        <img src="assets/event/president.jpg" alt="" />
        <br />
        Dr. Ahmed El-Saadi
       <br/>
        Conference President 

        <br />
      </div>-->
    </div>
  </div>
  <!--End container-->
  <div class="svg-decoration-container">
    <!--<div class="svg-decoration middle-left" data-jarallax-element="0 20">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 595.5502 940.8977"
        enable-background="new 0 0 595.5502 940.8977"
        xml:space="preserve"
      >
        <path
          fill="#4e5359"
          d="M373.7599,14.944C289.25-19.552,191.88,6.531,144.11,84.32
   C78.6499,190.932,0,345.204,0,471.004c0,70.759,20.99,148.159,49.1899,220.205c69.87,178.526,262.7,263.046,453.814,247.976l0,0
   c82.354-6.49,119.774-119.26,70.407-185.499c-61.899-83.05-110.851-186.085-79.618-282.682c2.777-8.589,5.69-17.008,8.724-25.258
   C557.3939,296.542,520.952,75.021,373.7599,14.944L373.7599,14.944z"
        />
      </svg>
    </div>-->
    <div class="svg-decoration bottom-right" data-jarallax-element="0 -50">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1444.184 1029.8875"
        enable-background="new 0 0 1444.184 1029.8875"
        xml:space="preserve"
      >
        <path
          fill="#7b333a"
          d="M1419.1539,768.5245c-36.38,185.74-219.6129,303.7499-400.1959,247.08
   c-160.16-50.26-349.103-118.27-497.925-197.4c-282.0127-149.95-473.504-497.559-516.634-581.413
   c-5.963-11.593-5.794-25.256,0.083-36.893l0,0c127.433-252.327,523.503-234.246,781.387-118.473
   c38.989,17.504,80.126,30.704,123.295,38.149l0,0c253.097,43.652,579.3799,294.627,530.02,546.67L1419.1539,768.5245z"
        />
      </svg>
    </div>
  </div>
  <!--<div class="curved-decoration">
    <svg
      version="1.1"
      class="bg-secondary-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
  
    </svg>
  </div>-->
</section>
