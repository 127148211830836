<section
  class="ts-book-seat"
  style="background-image: url(./assets/images/book_seat_img.jpg);"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="book-seat-content text-center mb-70">
          <h2 class="section-title white">
            <span>Hurry up!</span>
            Book your Seat
          </h2>
          <a href="#" class="btn">Buy Ticket</a>
        </div>
        <!-- book seat end-->
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="ts-footer-newsletter">
      <div
        class="ts-newsletter"
        style="
          background-image: url(./assets/images/shap/subscribe_pattern.png);
        "
      >
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <div class="ts-newsletter-content">
              <h2 class="section-title">
                <span>sUBSCRIBE TO nEWSLETTER</span>
                Want something extra?
              </h2>
            </div>
            <div class="newsletter-form">
              <form action="#" method="post" class="media align-items-end">
                <div class="email-form-group media-body">
                  <input
                    type="email"
                    name="email"
                    id="newsletter-form-email"
                    class="form-control"
                    placeholder="Your Email"
                    autocomplete="off"
                    required=""
                  />
                </div>
                <div class="d-flex ts-submit-btn">
                  <button class="btn">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- container end-->
</section>
