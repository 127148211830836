<div
  id="page-banner-area"
  class="page-banner-area"
  style="background-image: url(./assets/images/hero_area/banner_bg.jpg);"
>
  <!-- Subpage title start -->
  <div class="page-banner-title">
    <div class="text-center">
      <h2>About Event</h2>
      <ol class="breadcrumb">
        <li>
          <a href="#">Exibit /</a>
        </li>
        <li>
          About
        </li>
      </ol>
    </div>
  </div>
  <!-- Subpage title end -->
</div>
<!-- Page Banner end -->

<!-- ts intro start -->
<section class="ts-about-intro section-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title">
          <span>Join The Event</span>
          Why attend Exhibit
        </h2>
      </div>
      <!-- section title end-->
    </div>
    <!-- col end-->
    <div class="row">
      <div class="col-lg-4">
        <div class="about-intro-text text-right mb-60 mr-70">
          <i class="icon-speaker"></i>
          <h3 class="ts-title">Great Speakers</h3>
          <p>
            How you transform your business as technology, consumer, habits
            industry dynamic
          </p>
        </div>
        <!-- single intro text end-->

        <div class="about-intro-text text-right mr-70">
          <i class="icon-netwrorking"></i>
          <h3 class="ts-title">New People</h3>
          <p>
            How you transform your business as technology, consumer, habits
            industry dynamic
          </p>
        </div>
        <!-- single intro text end-->
        <div class="border-shap left"></div>
      </div>
      <!-- col end-->
      <div class="col-lg-4 align-self-center">
        <div class="about-video">
          <img
            class="img-fluid"
            src="assets/images/about/about_img.jpg"
            alt=""
          />
          <a
            href="https://www.youtube.com/watch?v=Bey4XXJAqS8"
            class="video-btn ts-video-popup"
            ><i class="icon icon-play"></i
          ></a>
        </div>
        <!-- entro video end-->
      </div>
      <!-- col end-->
      <div class="col-lg-4">
        <div class="about-intro-text mb-60 ml-70">
          <i class="icon-people"></i>
          <h3 class="ts-title">Networking</h3>
          <p>
            How you transform your business as technology, consumer, habits
            industry dynamic
          </p>
        </div>
        <!-- single intro text end-->

        <div class="about-intro-text ml-70">
          <i class="icon-fun"></i>
          <h3 class="ts-title">Have Fun</h3>
          <p>
            How you transform your business as technology, consumer, habits
            industry dynamic
          </p>
        </div>
        <!-- single intro text end-->
        <div class="border-shap left"></div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
</section>
<!-- ts intro end-->

<section class="ts-event-outcome">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="">
          <div class="outcome-content ts-exp-content">
            <h2 class="column-title">
              <span>Event Outcomes</span>
              Learn new things and connect people
            </h2>
            <p class="text-white">
              How you transform your business technology consumer, habits
              industry dynamic change the Find out from those leading
            </p>
            <a href="#" class="btn">Buy Ticket</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="outcome-content">
          <div class="outcome-img overlay">
            <img class="" src="assets/images/about/learn_img.jpg" alt="" />
          </div>
          <h3 class="img-title text-white">Learn Things</h3>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="outcome-content">
          <div class="outcome-img overlay">
            <img class="" src="assets/images/about/connect_img.jpg" alt="" />
          </div>
          <h3 class="img-title text-white">connect People</h3>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ts speaker start-->
<section
  id="ts-speakers"
  class="ts-speakers"
  style="background-image: url(./assets/images/speakers/speaker_bg.png);"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <h2 class="section-title text-center">
          <span>Listen to the</span>
          Event Speakers
        </h2>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="400ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker1.jpg"
              alt=""
            />
            <a
              href="#popup_1"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Fredric Martinsson</a></h3>
            <p>
              Founder, Edilta
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_1" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker1.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="500ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker2.jpg"
              alt=""
            />
            <a
              href="#popup_2"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Melisa Lundryn</a></h3>
            <p>
              Lead Designer, Payol
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_2" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker2.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="600ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker3.jpg"
              alt=""
            />
            <a
              href="#popup_3"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Agaton Johnsson</a></h3>
            <p>
              Developer Expert
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_3" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker3.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="700ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker4.jpg"
              alt=""
            />
            <a
              href="#popup_4"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Rebecca Henrikon</a></h3>
            <p>
              Founder, Cards
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_4" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker4.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="800ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker5.jpg"
              alt=""
            />
            <a
              href="#popup_5"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Fredric Martinsson</a></h3>
            <p>
              Founder, Edilta
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_5" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker5.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="900ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker6.jpg"
              alt=""
            />
            <a
              href="#popup_6"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Lundryn Melisa </a></h3>
            <p>
              Lead Designer, Payol
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_6" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker1.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="1000ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker7.jpg"
              alt=""
            />
            <a
              href="#popup_7"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Johnsson Agaton </a></h3>
            <p>
              Developer Expert
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_7" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker7.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->

      <div
        class="col-lg-3 col-md-6 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="1100ms"
      >
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker8.jpg"
              alt=""
            />
            <a
              href="#popup_8"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Henrikon Rebecca </a></h3>
            <p>
              Founder, Cards
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_8" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker8.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->

  <!-- shap img-->
  <!-- <div class="speaker-shap">
			<img class="shap1" src="assets/images/shap/home_speaker_memphis1.png" alt="">
			<img class="shap2" src="assets/images/shap/home_speaker_memphis2.png" alt="">
			<img class="shap3" src="assets/images/shap/home_speaker_memphis3.png" alt="">
		</div> -->
  <!-- shap img end-->
</section>
<!-- ts speaker end-->

<section
  class="ts-funfact"
  style="background-image: url(./assets/images/funfact_bg.jpg);"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="43">43</span>+
          </h3>
          <h4 class="funfact-title">Our Visionary Speakers</h4>
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="62">62</span>
          </h3>
          <h4 class="funfact-title">International Sponsors</h4>
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="28">28</span>+
          </h3>
          <h4 class="funfact-title">Workshops We offer</h4>
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="950">950</span>+
          </h3>
          <h4 class="funfact-title">Event Participants</h4>
        </div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
</section>

<section id="ts-sponsors" class="ts-sponsors section-bg">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-12 mx-auto">
        <h2 class="section-title text-center">
          <span>Who Healps us</span>
          Our Sponsors
        </h2>
      </div>
    </div>
    <!--/ Title row end -->
    <div class="sponsors-wrap">
      <h3 class="sponsor-title text-center">Platinum Sponsors</h3>
      <div class="row sponsor-padding text-center">
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-1.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 1 end -->
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-2.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 2 end -->
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-3.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 3 end -->
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-4.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 3 end -->
      </div>
    </div>

    <!--/ Content row 1 end -->

    <div class="sponsors-wrap">
      <h3 class="sponsor-title text-center">Gold Sponsors</h3>
      <div class="row sponsor-padding text-center">
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-5.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 1 end -->
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-6.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 2 end -->
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-7.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 3 end -->
        <div class="col-lg-3">
          <a href="#" class="sponsors-logo">
            <img
              class="img-fluid"
              src="assets/images/sponsors/sponsor-8.png"
              alt=""
            />
          </a>
        </div>
        <!-- Col 3 end -->
      </div>
    </div>

    <!--/ Content row 2 end -->

    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="general-btn text-center">
          <a class="btn" href="#">Become A Sponsor</a>
        </div>
      </div>
    </div>
    <!--/ Content row 3 end -->
  </div>
  <!--/ Container end -->
</section>
<!-- Sponsors end -->

<!-- ts map direction start-->
<section
  class="ts-map-direction wow fadeInUp"
  data-wow-duration="1.5s"
  data-wow-delay="400ms"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <h2 class="column-title">
          <span>Reach us</span>
          Get Direction to the Event Hall
        </h2>

        <div class="ts-map-tabs">
          <ul class="nav" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#profile"
                role="tab"
                data-toggle="tab"
                >Venue</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#buzz" role="tab" data-toggle="tab"
                >Time</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#references"
                role="tab"
                data-toggle="tab"
                >How to get there</a
              >
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content direction-tabs">
            <div role="tabpanel" class="tab-pane active" id="profile">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
            <!-- tab pane end-->
            <div role="tabpanel" class="tab-pane fade" id="buzz">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
            <div role="tabpanel" class="tab-pane fade" id="references">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
          </div>
        </div>
        <!-- map tabs end-->
      </div>
      <!-- col end-->
      <div class="col-lg-6 offset-lg-1">
        <div class="ts-map">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9028133968723!2d-73.99208268505396!3d40.74216397932861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a4119ce269%3A0x9dec0c979b575972!2sEataly+NYC+Flatiron!5e0!3m2!1sen!2sbd!4v1541577288827"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- col end-->
  </div>
  <!-- container end-->
  <div class="speaker-shap">
    <img class="shap1" src="assets/images/shap/Direction_memphis3.png" alt="" />
    <img class="shap2" src="assets/images/shap/Direction_memphis2.png" alt="" />
    <img class="shap3" src="assets/images/shap/Direction_memphis4.png" alt="" />
    <img class="shap4" src="assets/images/shap/Direction_memphis1.png" alt="" />
  </div>
</section>
<!-- ts map direction end-->
