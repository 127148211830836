<section>
  <div class="container">
    <div class="row"></div>
    <div class="col-md-12">
      <h1 class="text-center">Video Gallery 2019<br /><br /></h1>
      <div class="videolibraryblock">
        <div class="row headingblockred">
          <div class="col-md-8"><strong>TOPIC</strong></div>
          <div class="col-md-4"><strong>SPEAKER </strong></div>
        </div>
        <br />
        <!-- <p> -->
        <!--DATA-->
        <!-- Introduction video-->
        <!-- </p> -->
        <div class="row">
          <div class="col-md-8">
            <a (click)="openDialog('Qz5Gu9lOqQE', 'Youtube')" rel="noopener"
              >3rd Adore Conference 24 – 26 January 2019</a
            >
          </div>
          <div class="col-md-4">Introduction</div>
          <p><!-- Day 01 vid 1--></p>
          <div class="col-md-8">
            <a (click)="openDialog('d5I9zXt2EfA', 'Youtube')" rel="noopener"
              >Preparing for your first operation</a
            >
          </div>
          <div class="col-md-4">Dr. AlFawaz A.</div>
          <p><!-- Day 01 vid 2--></p>
          <div class="col-md-8">
            <a (click)="openDialog('5gxYAgPdKyw', 'Youtube')" rel="noopener"
              >Steps of Cataract Surgery</a
            >
          </div>
          <div class="col-md-4">Prof. T.Akahoshi</div>
          <p><!-- Day 01 vid 3--></p>
          <div class="col-md-8">
            <a (click)="openDialog('kagqLyY7fXU', 'Youtube')" rel="noopener"
              >Phacoemulsifcation and Phacodynamics Simplified</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jastaniah</div>
          <p><!-- Day 01 vid 4--></p>
          <div class="col-md-8">
            <a (click)="openDialog('6q1N-95XocE', 'Youtube')" rel="noopener"
              >Biometry: What you need to Know</a
            >
          </div>
          <div class="col-md-4">Dr. L. Benjamin</div>
          <p><!-- Day 01 vid 5--></p>
          <div class="col-md-8">
            <a (click)="openDialog('f-GJ0EWexVM', 'Youtube')" rel="noopener"
              >IOL Calculation in Special Case Scenarios</a
            >
          </div>
          <div class="col-md-4">Dr. L. Benjamin</div>
          <p><!-- Day 01 vid 6--></p>
          <div class="col-md-8">
            <a (click)="openDialog('ptLYdVB2_fw', 'Youtube')" rel="noopener"
              >The five Cornea Challenges In Cataract Surgery</a
            >
          </div>
          <div class="col-md-4">Dr. A.AlSaadi</div>
          <p><!-- Day 01 vid 7--></p>
          <div class="col-md-8">
            <a (click)="openDialog('avjuoMT2cNY', 'Youtube')" rel="noopener"
              >Changing Pharmaceutical Treatment in Cataract Surgery</a
            >
          </div>
          <div class="col-md-4">Dr. H.AlDhaheri</div>
          <p><!-- Day 01 vid 09--></p>
          <div class="col-md-8">
            <a (click)="openDialog('jeGYPyEpPFs', 'Youtube')" rel="noopener"
              >Incision Construction and Understanding Ophtalmicviscosurgical
              devises (OVDs )</a
            >
          </div>
          <div class="col-md-4">Dr. W.AlDairi</div>
          <p><!-- Day 01 vid 10--></p>
          <div class="col-md-8">
            <a (click)="openDialog('2ZQB_leEcqU', 'Youtube')" rel="noopener"
              >Capsulorrhexis: Pearls and pitfalls</a
            >
          </div>
          <div class="col-md-4">Prof. T.Akahoshi</div>
          <p><!-- Day 01 vid 11--></p>
          <div class="col-md-8">
            <a (click)="openDialog('dgKUDxpEyyo', 'Youtube')" rel="noopener"
              >Primary Posterior capsulorrhexis</a
            >
          </div>
          <div class="col-md-4">Dr. L. Benjamin</div>
          <p><!-- Day 01 vid 12--></p>
          <div class="col-md-8">
            <a (click)="openDialog('1jiI3TVS4TE', 'Youtube')" rel="noopener"
              >Phaco Techniques : The Basics</a
            >
          </div>
          <div class="col-md-4">Dr. A.AlQassimi</div>
          <p><!-- Day 01 vid 13--></p>
          <div class="col-md-8">
            <a (click)="openDialog('brGAYBUSopk', 'Youtube')" rel="noopener"
              >Phaco Techniques : Raising the Bar</a
            >
          </div>
          <div class="col-md-4">Prof. T. Akahoshi</div>
          <p><!-- Day 01 vid 14--></p>
          <div class="col-md-8">
            <a (click)="openDialog('iSYBO6iutP0', 'Youtube')" rel="noopener"
              >Intraocular Lenses : Tricks</a
            >
          </div>
          <div class="col-md-4">Dr. S.Kallel</div>
          <p><!-- Day 01 vid 15--></p>
          <div class="col-md-8">
            <a (click)="openDialog('xxc04VB_A_w', 'Youtube')" rel="noopener"
              >Learning Extra Intracapsular Cataract extraction: two essential
              skills</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jacob</div>
          <p><!-- Day 01 vid 15--></p>
          <div class="col-md-8">
            <a (click)="openDialog('b-uqh3RZKyA', 'Youtube')" rel="noopener"
              >Toric IOLs :Successful Planning and dealing with failure</a
            >
          </div>
          <div class="col-md-4">Prof.T.Akahoshi</div>
          <p><!-- Day 02 vid 1--></p>
          <div class="col-md-8">
            <a (click)="openDialog('972n-poY7wU', 'Youtube')" rel="noopener"
              >Cornea : The innocent Bystander in Cataract surgery</a
            >
          </div>
          <div class="col-md-4">Dr. H. Al Dhaheri</div>
          <p><!-- Day 02 vid 2--></p>
          <div class="col-md-8">
            <a (click)="openDialog('KvG9-cBtqLg', 'Youtube')" rel="noopener"
              >Small Pupil and Floppy Iris : Mastering the game</a
            >
          </div>
          <div class="col-md-4">Dr. A.Al-Saati</div>
          <p><!-- Day 02 vid 3--></p>
          <div class="col-md-8">
            <a (click)="openDialog('KvG9-cBtqLg', 'Youtube')" rel="noopener"
              >Running Capuslarhexis: Coming to rescue</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jacob</div>
          <p><!-- Day 02 vid 4--></p>
          <div class="col-md-8">
            <a (click)="openDialog('Vja8Edv8aXw', 'Youtube')" rel="noopener"
              >Posterior Capsule : Dealing with reality</a
            >
          </div>
          <div class="col-md-4">Dr. L Benjamin</div>
          <p><!-- Day 02 vid 6--></p>
          <div class="col-md-8">
            <a (click)="openDialog('c916drTD1nw', 'Youtube')" rel="noopener"
              >Pseudoexfoliation and Zonulopathy</a
            >
          </div>
          <div class="col-md-4">Prof. T. Akahoshi</div>
          <p><!-- Day 02 vid 7--></p>
          <div class="col-md-8">
            <a (click)="openDialog('x6uxz5zqNc', 'Youtube')" rel="noopener"
              >Vitrectomy for Cataract Surgeon</a
            >
          </div>
          <div class="col-md-4">Dr. A.El Barky</div>
          <p><!-- Day 02 vid 8--></p>
          <div class="col-md-8">
            <a (click)="openDialog('YsIlUiqP6Wk', 'Youtube')" rel="noopener"
              >Optic Disc Clinical Assessment: Quick and Easy</a
            >
          </div>
          <div class="col-md-4">Prof. S Vernon</div>
          <p><!-- Day 02 vid 9--></p>
          <div class="col-md-8">
            <a (click)="openDialog('mTEWn2uljLM', 'Youtube')" rel="noopener"
              >Optic Disc Imaging</a
            >
          </div>
          <div class="col-md-4">Dr. W. Ghobashy</div>
          <p><!-- Day 02 vid 10--></p>
          <div class="col-md-8">
            <a (click)="openDialog('FO9cPE34clM', 'Youtube')" rel="noopener"
              >When Should I treat Glaucoma Suspect?</a
            >
          </div>
          <div class="col-md-4">Prof. S Vernon</div>
          <p><!-- Day 02 vid 11--></p>
          <div class="col-md-8">
            <a (click)="openDialog('uThCERM8aQw', 'Youtube')" rel="noopener"
              >Normal tension glaucoma : Diagnosis &amp; Management</a
            >
          </div>
          <div class="col-md-4">Dr. M. Suhaib</div>
          <p><!-- Day 02 vid 12--></p>
          <div class="col-md-8">
            <a (click)="openDialog('IUYvixFfqHk', 'Youtube')" rel="noopener"
              >Myopic Optic Disc Glaucoma: culties in Diagnosis and follow up</a
            >
          </div>
          <div class="col-md-4">Prof. T.Shaarawy</div>
          <p><!-- Day 02 vid 13--></p>
          <div class="col-md-8">
            <a (click)="openDialog('dIotAH3epV8', 'Youtube')" rel="noopener"
              >The truth about the optic disc perfusion in Glaucoma</a
            >
          </div>
          <div class="col-md-4">Dr. A.Abdelshafik</div>
          <p><!-- Day 02 vid 14--></p>
          <div class="col-md-8">
            <a (click)="openDialog('Km-H57FNb1Y', 'Youtube')" rel="noopener"
              >European Glaucoma Guidelines</a
            >
          </div>
          <div class="col-md-4">Dr. O. Hammam</div>
          <p><!-- Day 02 vid 15--></p>
          <div class="col-md-8">
            <a (click)="openDialog('u_G9ZQo31jM', 'Youtube')" rel="noopener"
              >Tonometry : Current Clinical Utility and updates</a
            >
          </div>
          <div class="col-md-4">Prof. S Vernon</div>
          <p><!-- Day 02 vid 16--></p>
          <div class="col-md-8">
            <a (click)="openDialog('ptwU2MjTZfQ', 'Youtube')" rel="noopener"
              >Target IOP</a
            >
          </div>
          <div class="col-md-4">Prof. A.Abdelshafik</div>
          <p><!-- Day 02 vid 17--></p>
          <div class="col-md-8">
            <a (click)="openDialog('WEnnjqu51Rk', 'Youtube')" rel="noopener"
              >Too many anti- glaucoma Medications – Which one to choose?</a
            >
          </div>
          <div class="col-md-4">Dr. A.Albusaidi</div>
          <p><!-- Day 02 vid 18--></p>
          <div class="col-md-8">
            <a (click)="openDialog('6pL9k8i8oNc', 'Youtube')" rel="noopener"
              >Visual Field Progression: A decision making tool</a
            >
          </div>
          <div class="col-md-4">Dr. A Al Naqi</div>
          <p><!-- Day 02 vid 19--></p>
          <div class="col-md-8">
            <a (click)="openDialog('Lmgd58JTwp0', 'Youtube')" rel="noopener"
              >Non tolerant glaucoma patient : Do I have other options</a
            >
          </div>
          <div class="col-md-4">Prof. T.Shaarawy</div>
          <p><!-- Day 02 vid 20--></p>
          <div class="col-md-8">
            <a (click)="openDialog('VxRJPnaOZuc', 'Youtube')" rel="noopener"
              >Indication of Glaucoma Surgery : Before it is too late</a
            >
          </div>
          <div class="col-md-4">Prof. S Vernon</div>
          <p><!-- Day 02 vid 21--></p>
          <div class="col-md-8">
            <a (click)="openDialog('cFqQwZBfVYI', 'Youtube')" rel="noopener"
              >Trabeculectomy : Why it is still the Gold standard ?</a
            >
          </div>
          <div class="col-md-4">Prof. A.Abdelshafik</div>
          <p><!-- Day 02 vid 22--></p>
          <div class="col-md-8">
            <a (click)="openDialog('tpbEikGAfUI', 'Youtube')" rel="noopener"
              >Non- Penetrating Deep Sclerectomy</a
            >
          </div>
          <div class="col-md-4">Dr. T.Shaarawy</div>
          <p><!-- Day 02 vid 23--></p>
          <div class="col-md-8">
            <a (click)="openDialog('Dko1a-hg260', 'Youtube')" rel="noopener"
              >Tubes: Who, When and Which ?</a
            >
          </div>
          <div class="col-md-4">Prof. A.Abdelshafik</div>
          <p><!-- Day 02 vid 24--></p>
          <div class="col-md-8">
            <a (click)="openDialog('ZJwB2WFlDzk', 'Youtube')" rel="noopener"
              >Glaucoma Surgery ( MIGS): Present and Future</a
            >
          </div>
          <div class="col-md-4">Prof. T. Shaarawy</div>
          <p><!-- Day 02 vid 25--></p>
          <div class="col-md-8">
            <a (click)="openDialog('Mby4-ont5kU', 'Youtube')" rel="noopener"
              >Postoperative Bleb Management : Mission possible</a
            >
          </div>
          <div class="col-md-4">Prof. A.Abdelshafik</div>
          <p><!-- Day 02 vid 26--></p>
          <div class="col-md-8">
            <a (click)="openDialog('RecCcftkCMw', 'Youtube')" rel="noopener"
              >Dislocated Crystalline Lens: Causes and approach to management</a
            >
          </div>
          <div class="col-md-4">Dr. A. Al Mahmood</div>
          <p><!-- Day 02 vid 27--></p>
          <div class="col-md-8">
            <a (click)="openDialog('94xiYxKGW48', 'Youtube')" rel="noopener"
              >IOL implantation in Poor capsular Support</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jacob</div>
          <p><!-- Day 02 vid 28--></p>
          <div class="col-md-8">
            <a (click)="openDialog('94xiYxKGW48', 'Youtube')" rel="noopener"
              >Subluxated cataract and IOLs – the Paperclip capsule
              stabilizer</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jacob</div>
          <p><!-- Day 02 vid 29--></p>
          <div class="col-md-8">
            <a (click)="openDialog('cp6d724IWEw', 'Youtube')" rel="noopener"
              >IOL exchange: when everything fails</a
            >
          </div>
          <div class="col-md-4">Dr. M. Al Jindan</div>
          <p><!-- Day 03 vid 1--></p>
          <div class="col-md-8">
            <a (click)="openDialog('pMywxSj79qY', 'Youtube')" rel="noopener"
              >Astigmatic Keratotomy and Peripheral Limbal relaxing incisions</a
            >
          </div>
          <div class="col-md-4">Dr. O.Giledi</div>
          <p><!-- Day 03 vid 2--></p>
          <div class="col-md-8">
            <a (click)="openDialog('-QTv7ZngXkE', 'Youtube')" rel="noopener"
              >Monovision in Cataract surgery</a
            >
          </div>
          <div class="col-md-4">Dr. A.Alsaati</div>
          <p><!-- Day 03 vid 3--></p>
          <div class="col-md-8">
            <a (click)="openDialog('vDZjyCdY9y8', 'Youtube')" rel="noopener"
              >Pearls for Successful start with Premium IOLS</a
            >
          </div>
          <div class="col-md-4">Dr. O.Giledi</div>
          <p><!-- Day 03 vid 4--></p>
          <div class="col-md-8">
            <a (click)="openDialog('v16w4GWh-sg', 'Youtube')" rel="noopener"
              >Multifocal and Extended Depth of Focus IOLs</a
            >
          </div>
          <div class="col-md-4">Dr. M.AlJindan</div>
          <p><!-- Day 03 vid 5--></p>
          <div class="col-md-8">
            <a (click)="openDialog('LAWZp6i46kY', 'Youtube')" rel="noopener"
              >Dealing with Unhappy patient after Multifocal IOLs</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jastania</div>
          <p><!-- Day 03 vid 6--></p>
          <div class="col-md-8">
            <a (click)="openDialog('Lm8Mr9AaHcQ', 'Youtube')" rel="noopener"
              >Introduction to FLACS</a
            >
          </div>
          <div class="col-md-4">Dr. Almahmood</div>
          <p><!-- Day 03 vid 7--></p>
          <div class="col-md-8">
            <a (click)="openDialog('f2hMK_shhtw', 'Youtube')" rel="noopener"
              >FLACS vs Phaco</a
            >
          </div>
          <div class="col-md-4">Dr. Mohd Aly</div>
          <p><!-- Day 03 vid 8--></p>
          <div class="col-md-8">
            <a (click)="openDialog('Imp-dTNIQQ8', 'Youtube')" rel="noopener"
              >Is it Congenital Glaucoma?</a
            >
          </div>
          <div class="col-md-4">Dr. Aref Khan</div>
          <p><!-- Day 03 vid 9--></p>
          <div class="col-md-8">
            <a (click)="openDialog('SkvisiNNyyI', 'Youtube')" rel="noopener"
              >Surgical Approach to Congenial Glaucoma</a
            >
          </div>
          <div class="col-md-4">Prof. S. Al Obeidan</div>
          <p><!-- Day 03 vid 10--></p>
          <div class="col-md-8">
            <a (click)="openDialog('e1ZcJ3hsNiw', 'Youtube')" rel="noopener"
              >Pseudoexexfoliation</a
            >
          </div>
          <div class="col-md-4">Dr. Scott Smith</div>
          <p><!-- Day 03 vid 11--></p>
          <div class="col-md-8">
            <a (click)="openDialog('rhOCKu76pM0', 'Youtube')" rel="noopener"
              >Pigmentary Glaucoma</a
            >
          </div>
          <div class="col-md-4">Dr. D. Edward</div>
          <p><!-- Day 03 vid 13--></p>
          <div class="col-md-8">
            <a (click)="openDialog('ybHKcZNmF2Q', 'Youtube')" rel="noopener"
              >Chronic Angle Closure Glaucoma: PI angle Still Closed</a
            >
          </div>
          <div class="col-md-4">Dr. Scott Smith</div>
          <p><!-- Day 03 vid 14--></p>
          <div class="col-md-8">
            <a (click)="openDialog('lrVmujJBKtU', 'Youtube')" rel="noopener"
              >Uveitic Glaucoma</a
            >
          </div>
          <div class="col-md-4">Prof. S Al Obeidan</div>
          <p><!-- Day 03 vid 15--></p>
          <div class="col-md-8">
            <a (click)="openDialog('eOtor8PUYaA', 'Youtube')" rel="noopener"
              >Gonioscopy: The Key to Glaucoma Classification</a
            >
          </div>
          <div class="col-md-4">Dr. A.Albusaidi</div>
          <p><!-- Day 03 vid 16--></p>
          <div class="col-md-8">
            <a (click)="openDialog('uagEM9JYqUI', 'Youtube')" rel="noopener"
              >Peripheral Iridotomy</a
            >
          </div>
          <div class="col-md-4">Dr. A.Albusaidi</div>
          <p><!-- Day 03 vid 17--></p>
          <div class="col-md-8">
            <a (click)="openDialog('wL9-jRQDIkg', 'Youtube')" rel="noopener"
              >UBM: The Forgotten treasure</a
            >
          </div>
          <div class="col-md-4">Dr. M Puech</div>
          <p><!-- Day 03 vid 18--></p>
          <div class="col-md-8">
            <a (click)="openDialog('jY8K-fDZreE', 'Youtube')" rel="noopener"
              >OCT Vs visual field in progression evaluation</a
            >
          </div>
          <div class="col-md-4">Dr.D. Edward</div>
          <p><!-- Day 03 vid 19--></p>
          <div class="col-md-8">
            <a (click)="openDialog('-6_EULpSVcA', 'Youtube')" rel="noopener"
              >Visual field improvement in glaucoma: is it real ?</a
            >
          </div>
          <div class="col-md-4">Prof. Shaarawy</div>
          <p><!-- Day 03 vid 20--></p>
          <div class="col-md-8">
            <a (click)="openDialog('z3HFenMFDls', 'Youtube')" rel="noopener"
              >Unmet needs in glaucoma</a
            >
          </div>
          <div class="col-md-4">Dr. O.Hamam</div>
          <p><!-- Day 03 vid 21--></p>
          <div class="col-md-8">
            <a (click)="openDialog('eVnwkjT9xAY', 'Youtube')" rel="noopener"
              >Pearls from Clinical Trials in Glaucoma</a
            >
          </div>
          <div class="col-md-4">Dr.D. Edward</div>
          <p><!-- Day 03 vid 22--></p>
          <div class="col-md-8">
            <a (click)="openDialog('M2jgBbUM1rU', 'Youtube')" rel="noopener"
              >Cataract in Uveitis patients</a
            >
          </div>
          <div class="col-md-4">Dr. A.AlFawaz</div>
          <p><!-- Day 03 vid 23--></p>
          <div class="col-md-8">
            <a (click)="openDialog('XDJZbQTMNlE', 'Youtube')" rel="noopener"
              >Rock Hard Cataract</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jacob</div>
          <p><!-- Day 03 vid 24--></p>
          <div class="col-md-8">
            <a (click)="openDialog('vJ2H11X-U2s', 'Youtube')" rel="noopener"
              >Posterior Polar Cataract</a
            >
          </div>
          <div class="col-md-4">Dr. A.AlSaati</div>
          <p><!-- Day 03 vid 25--></p>
          <div class="col-md-8">
            <a (click)="openDialog('shQTuezbx3Q', 'Youtube')" rel="noopener"
              >Traumatic Catarac</a
            >
          </div>
          <div class="col-md-4">Dr. Benjamin</div>
          <p><!-- Day 03 vid 26--></p>
          <div class="col-md-8">
            <a (click)="openDialog('KQB8bA8SV8w', 'Youtube')" rel="noopener"
              >White mature Cataract</a
            >
          </div>
          <div class="col-md-4">Dr. A.AlSaati</div>
          <p><!-- Day 03 vid 27--></p>
          <div class="col-md-8">
            <a (click)="openDialog('drnE1yuXETE', 'Youtube')" rel="noopener"
              >Fuchs Endothelial Dystrophy</a
            >
          </div>
          <div class="col-md-4">Dr. S. Jastania</div>
          <p><!-- Day 03 vid 18--></p>
          <div class="col-md-8">
            <a (click)="openDialog('d9FvRdGeMuo', 'Youtube')" rel="noopener"
              >Combined Cataract and Glaucoma surgery: When, what and how ?</a
            >
          </div>
          <div class="col-md-4">Dr. Mohd Aly</div>
        </div>
      </div>
      <div class="sep25px">&nbsp;</div>
      <div class="sep25px">&nbsp;</div>
    </div>
  </div>
</section>
