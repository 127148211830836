<header class="header default">
  <div class="header-section">
    <!--Container-->
    <div class="container">
      <nav class="navbar navbar-expand-lg header-navbar">
        <a class="navbar-brand navbar-logo scroll" [routerLink]="['./']">
          <img
            class="mb-0 logo-light"
            src="./assets/event/adore-logo-2022.png"
            style="width: 300px"
            alt=""
          />

          <img
            class="mb-0 logo-dark"
            src="./assets/event/adore-logo-2022.png"
            style="width: 280px"
            alt=""
          />
        </a>
        <button
          class="navbar-toggler btn-navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target=".nav-menu"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <span class="fa fa-bars"></span>
        </button>
        <div
          class="
            nav-menu
            collapse
            navbar-collapse navbar-collapse
            justify-content-end
            py-0
          "
        >
          <ul class="navbar-nav header-navbar-nav">
            <li><a class="nav-link scroll" [routerLink]="['/']">Home</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Committees
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#steering">Steering Committee</a>
                <a class="dropdown-item" href="#scientific">Scientific Committee</a>
                <a class="dropdown-item" href="#organizing">Organizing Committee</a>
            
              </div>
            </li>
           
          
            <li>
              <a class="nav-link scroll" [routerLink]="['./', 'speakers']"
                >Speakers</a
              >
            </li>
            <li>
              <a class="nav-link scroll" href="https://dev.adore-conference.com/agenda">Program</a>
            </li>
            <li>
              <a class="nav-link scroll" href="#accomodation">Accomodation</a>
            </li>

            <!--                <li><a class="nav-link scroll" href="#tickets">Tickets</a>
              </li>-->
              <li>
                <a class="nav-link scroll" [routerLink]="['./', 'exhibition']"
                  >Sponsors</a
                >
              </li>

            <li>
              <a class="nav-link scroll" [routerLink]="['./', 'gallery']"
                >Gallery</a
              >
            </li>
            
            <li>
              <a class="nav-link scroll" [routerLink]="['./', 'library']"
                >Video Library
              </a>
            </li>
            <!--                <li class="mb-4 mb-lg-0"><a class="nav-link scroll" href="#faq">FAQ</a>
              </li>-->
          </ul>
          <ul>
            <a
            class="scroll btn btn-primary btn-lg mb-5 mb-lg-0"
            [routerLink]="['./', 'register-soon']"
          >
          Registration
          </a>
           </ul>
        </div>
    
          
     
      </nav>

    </div>
    <!--End container-->
  </div>
</header>
