<!-- ts blog start-->
<section class="ts-blog section-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title">
          <span>Info Update</span>
          Latest News
        </h2>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-lg-4">
        <div class="post">
          <div class="post-media post-image">
            <a href="#"
              ><img src="assets/images/blog/blog1.jpg" class="img-fluid" alt=""
            /></a>
          </div>

          <div class="post-body">
            <div class="post-meta">
              <span class="post-author">
                <a href="#">BY Admin</a>
              </span>

              <div class="post-meta-date">
                October 8, 2018
              </div>
            </div>
            <div class="entry-header">
              <h2 class="entry-title">
                <a href="#">Check upcoming Events</a>
              </h2>
            </div>
            <!-- header end -->

            <div class="entry-content">
              <p>How you transform your business asap technology, consumer,</p>
            </div>

            <div class="post-footer">
              <a href="news-single.html" class="btn-link"
                >Read More <i class="icon icon-arrow-right"></i
              ></a>
            </div>
          </div>
          <!-- post-body end -->
        </div>
        <!-- post end-->
      </div>
      <!-- col end-->
      <div class="col-lg-4">
        <div class="post">
          <div class="post-media post-image">
            <a href="#"
              ><img src="assets/images/blog/blog2.jpg" class="img-fluid" alt=""
            /></a>
          </div>

          <div class="post-body">
            <div class="post-meta">
              <span class="post-author">
                <a href="#">BY Admin</a>
              </span>

              <div class="post-meta-date">
                October 8, 2018
              </div>
            </div>
            <div class="entry-header">
              <h2 class="entry-title">
                <a href="#">Adding a New Digital</a>
              </h2>
            </div>
            <!-- header end -->

            <div class="entry-content">
              <p>How you transform your business asap technology, consumer,</p>
            </div>

            <div class="post-footer">
              <a href="news-single.html" class="btn-link"
                >Read More <i class="icon icon-arrow-right"></i
              ></a>
            </div>
          </div>
          <!-- post-body end -->
        </div>
        <!-- post end-->
      </div>
      <!-- col end-->
      <div class="col-lg-4">
        <div class="post">
          <div class="post-media post-image">
            <a href="#"
              ><img src="assets/images/blog/blog3.jpg" class="img-fluid" alt=""
            /></a>
          </div>

          <div class="post-body">
            <div class="post-meta">
              <span class="post-author">
                <a href="#">BY Admin</a>
              </span>

              <div class="post-meta-date">
                October 8, 2018
              </div>
            </div>
            <div class="entry-header">
              <h2 class="entry-title">
                <a href="#">West Elm At Evantor</a>
              </h2>
            </div>
            <!-- header end -->

            <div class="entry-content">
              <p>How you transform your business asap technology, consumer,</p>
            </div>

            <div class="post-footer">
              <a href="news-single.html" class="btn-link"
                >Read More <i class="icon icon-arrow-right"></i
              ></a>
            </div>
          </div>
          <!-- post-body end -->
        </div>
        <!-- post end-->
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
  <!-- shap image-->
  <div class="speaker-shap">
    <img class="shap2" src="assets/images/shap/news_memphis2.png" alt="" />
  </div>
</section>
<!-- ts blog end-->
