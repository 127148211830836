<section id="gallery" style="padding: 150px 0" class="">
  <!--Container-->
  <div class="container spacer-double-sm position-relative">
    <div class="col">
      <div class="mb-5 pb-5 text-center">
        <!-- <span class="icon-title icon-bg-dark rounded-circle mb-3">
            <img
              class="icon-title-inner"
              src="assets/svg/topic-icon.svg"
              alt=""
            />
          </span> -->
        <h2 class="h1">Gallery</h2>
        <!-- <p class="w-md-60 mx-auto mb-0 lead">
            Whether you’re new to the speaking field or a seasoned pro you’ll
            connect with old colleagues and make valuable new friends.
          </p> -->
      </div>
    </div>
    <div class="svg-decoration-container">
      <div
        class="svg-decoration middle-left z-index-2 boundary"
        data-jarallax-element="50 0"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 618.7321 953.8541"
          enable-background="new 0 0 618.7321 953.8541"
          xml:space="preserve"
        >
          <path
            fill="#4e5359"
            d="M537.1101,778.5433
    c51.8586-99.02,78.9947-189.8956,81.4083-272.6271c1.7134-58.731-6.8779-121.056-25.7739-186.975l0,0
    C536.3835,122.3246,352.033-9.579,147.7484,0.5448L100,2.9109C42.4495,5.763-1.8924,54.7289,0.9596,112.2795
    c1.0715,21.6219,8.8424,42.3749,22.2356,59.3831c89.1792,113.2492,116.0558,219.6618,80.6299,319.2379
    c-20.3856,57.3003-45.0635,108.945-74.0337,154.9342l0,0c-56.9401,90.3902-29.8234,209.825,60.5669,266.765
    c7.9332,4.9974,16.2192,9.4114,24.7926,13.207l0,0C272.6364,995.5298,457.2053,931.1155,537.1101,778.5433L537.1101,778.5433z"
          />
        </svg>
      </div>
      <div
        class="svg-decoration bottom-right boundary z-index-2"
        data-jarallax-element="-50 0"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 645.0645 726.0726"
          enable-background="new 0 0 645.0645 726.0726"
          xml:space="preserve"
        >
          <path
            fill="none"
            stroke="#234DD4"
            stroke-width="35"
            stroke-miterlimit="10"
            d="
    M173.2631,110.4345c-50.5356,54.1457-83.8452,107.4337-99.9288,159.864c-11.4178,37.2202-16.8839,78.5479-16.3984,123.9829l0,0
    c1.4481,135.519,96.0394,252.1913,228.3301,281.6306l30.9209,6.881c37.2686,8.2935,74.204-15.1954,82.4975-52.4639
    c3.1159-14.0019,1.7991-28.6263-3.7679-41.8464c-37.0681-88.0264-35.5347-160.7343,4.6003-218.1236
    c23.0954-33.0243,47.9389-61.6809,74.5306-85.9698l0,0c52.2652-47.7392,55.9341-128.8087,8.1949-181.0738
    c-4.1899-4.5871-8.7077-8.8635-13.5178-12.7954l0,0C380.3669,18.2949,251.1294,27.0056,173.2631,110.4345L173.2631,110.4345z"
          />
        </svg>
      </div>
    </div>
    <!--Row-->
    <div
      class="row align-items-center justify-content-between z-index-2 position-relative"
    >
      <div class="col-md-3 py-2" *ngFor="let one of gallary">
        <img
          src="./assets/img2/gallery/{{ one }}.jpeg"
          class="img-thumbnail"
          alt="..."
          (click)="openDialog(one.toString())"
        />
      </div>
    </div>
    <!--End row-->
  </div>
  <!--End container-->
  <!-- <div class="curved-decoration">
    <svg
      version="1.1"
      class="bg-secondary-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2560 221"
      enable-background="new 0 0 2560 221"
      xml:space="preserve"
    >
      <polygon points="2560,221 0,221 2560,0 "></polygon>
    </svg>
  </div> -->
</section>
