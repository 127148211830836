<!-- ts map direction start-->
<section
  class="ts-map-direction wow fadeInUp"
  data-wow-duration="1.5s"
  data-wow-delay="400ms"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <h2 class="column-title">
          <span>Reach us</span>
          Get Direction to the Event Hall
        </h2>

        <div class="ts-map-tabs">
          <ul class="nav" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#profile"
                role="tab"
                data-toggle="tab"
                >Venue</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#buzz" role="tab" data-toggle="tab"
                >Time</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#references"
                role="tab"
                data-toggle="tab"
                >How to get there</a
              >
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content direction-tabs">
            <div role="tabpanel" class="tab-pane active" id="profile">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
            <!-- tab pane end-->
            <div role="tabpanel" class="tab-pane fade" id="buzz">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
            <div role="tabpanel" class="tab-pane fade" id="references">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
          </div>
        </div>
        <!-- map tabs end-->
      </div>
      <!-- col end-->
      <div class="col-lg-6 offset-lg-1">
        <div class="ts-map">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9028133968723!2d-73.99208268505396!3d40.74216397932861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a4119ce269%3A0x9dec0c979b575972!2sEataly+NYC+Flatiron!5e0!3m2!1sen!2sbd!4v1541577288827"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- col end-->
  </div>
  <!-- container end-->
  <div class="speaker-shap">
    <img class="shap1" src="images/shap/Direction_memphis3.png" alt="" />
    <img class="shap2" src="images/shap/Direction_memphis2.png" alt="" />
    <img class="shap3" src="images/shap/Direction_memphis4.png" alt="" />
    <img class="shap4" src="images/shap/Direction_memphis1.png" alt="" />
  </div>
</section>
<!-- ts map direction end-->
